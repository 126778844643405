<template>
  <!-- <div class="regist-main"> -->
  <div>
    <div class="text-home">
      <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
    </div>

    <!-- <el-alert title="恭喜你注册成功" type="success" :hidden="isHiddenSuccess">
    </el-alert> -->
    <!-- <el-alert :title="message" type="error" :hidden="isHiddenError" :closable="isClose">
    </el-alert> -->
    <!-- <el-col :span="6"> </el-col> -->
    <!-- <el-col :span="12"> -->
    <h1 class="brand-info__text">我的健康——个人平台</h1>
    <el-tabs v-model="activeName">
      <el-tab-pane label="手机号注册" name="phone">
        <el-form :label-position="labelPosition" :model="mobileForm" :rules="dataRule" ref="mobileForm"
          @keyup.enter.native="mobileFormSubmit()" status-icon>
          <el-form-item prop="userName">
            <el-input v-model="mobileForm.userName" placeholder="姓名" style="font-size: 18px"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-input v-model="mobileForm.password" placeholder="密码" style="font-size: 18px"></el-input>
          </el-form-item> -->
          <el-form-item prop="gender">
            <!-- <el-input v-model="mobileForm.gender" placeholder="性别" style="font-size: 18px"></el-input> -->
            <el-select v-model="mobileForm.gender" placeholder="性别" style="font-size: 18px!important; width: 100%;">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="age">
            <el-input v-model.number="mobileForm.age" placeholder="年龄，请输入数字" style="font-size: 18px"
              oninput="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-input v-model="mobileForm.mobile" placeholder="手机号" style="width: 100%; font-size: 18px"></el-input>
            <!-- <el-button style="color: dodgerblue" type="text" @click="getCode(mobileForm.mobile)">&nbsp;&nbsp;获取验证码
            </el-button> -->
          </el-form-item>
          <!-- <el-form-item prop="code">
            <el-input v-model="mobileForm.code" placeholder="验证码" style="font-size: 18px"></el-input>
          </el-form-item> -->
          <el-form-item label="健康小屋名称" prop="stationName">
            <el-input v-model="mobileForm.stationName" placeholder="请输入健康小屋名称" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="项目名称" prop="unitName">
            <el-select v-model="mobileForm.unitName" placeholder="请下拉选择项目名称" style="font-size: 18px!important; width: 100%;">
              <el-option v-for="item in unitname_option" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份" prop="characterName">
            <el-select v-model="mobileForm.characterName" placeholder="请下拉选择身份角色" style="font-size: 18px!important; width: 100%;">
              <el-option label="保洁" value="保洁"></el-option>
              <el-option label="保安" value="保安"></el-option>
              <el-option label="工程" value="工程"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="regist-btn-submit" type="primary" @click="mobileFormSubmit()"
              style="font-size: 18px">注册</el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button class="regist-btn-submit" type="primary" @click="gotoLogin()" style="font-size: 18px">已有帐号？马上登录</el-button>
          </el-form-item> -->
          * 注册个人账号后，您的账号将与{{ mobileForm.stationName }}健康小屋绑定，后续测量更便捷！
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹窗提示，确认手机号是否无误 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="80%">
      <div style="font-size: 18px; padding:2%;">您的手机号是{{ mobileForm.mobile }}</div>
      <div style="font-size: 18px; padding:2%;">请确认手机号是否无误！</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmPhoneDialog()">确 定</el-button>

      </span>
    </el-dialog>
    <!-- </el-col> -->
    <!-- <el-col :span="6"> </el-col> -->
  </div>
</template>

<script>
// import VDistpicker from "v-distpicker";
export default {
  // components: { VDistpicker },
  data() {
    return {
      // isHiddenSuccess: true,
      // isHiddenError: true,
      dialogVisible: false,
      confirmPhone: false,
      labelPosition: 'top',
      isClose: false,
      message: "",
      activeName: "phone",
      enid: "",

      mobileForm: {
        userName: "",
        // password: "",
        age: "",
        gender: "男",
        mobile: "",
        code: "",
        stationName: "",
        unitName: "",
        characterName: "",
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
          { pattern: /^[\u4e00-\u9fa5]{0,}$/, message: "姓名格式错误,请输入中文名", trigger: "blur", },
        ],
        age: [
          { required: true, message: "年龄不能为空", trigger: "blur" },
          { pattern: /^[0-9]*$/, message: "请输入数字(年龄必须在15到80之间)", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不对", trigger: "blur" },
        ],
        // code: [
        //   { required: true, message: "验证码不能为空", trigger: "blur" },
        // ],
      },
      unitname_option: [],
    };
  },
  created() {
    // this.isHiddenSuccess = true;
    // this.isHiddenError = true;
  },
  methods: {


    // 确认手机号
    confirmPhoneDialog() {
      // 用户点击确认后调用
      this.confirmPhone = true;
      this.dialogVisible = false;  // 关闭弹窗
      this.final_submit();
    },
    // 提交表单--手机号注册
    mobileFormSubmit() {
      // 确认手机号弹窗
      this.dialogVisible = true;
    },
    final_submit() {
      if (this.confirmPhone) {
        for (let key in this.mobileForm) {
          if (key != "code") {
            if (!this.mobileForm[key]) {
              this.$message({
                message: "请确保所有字段都已填写！",
                type: "warning",
              });
              return;  // 如果有空值，停止提交
            }
          }
        }
        // 检查年龄
        this.validateAge()

        console.log("yes,可以提交")

        this.$http({
          url: this.$http.adornUrl("/personal/register/app/property"),
          method: "post",
          params: this.$http.adornParams({
            username: this.mobileForm.userName,
            // password: this.mobileForm.password,
            userPhone: this.mobileForm.mobile,
            age: this.mobileForm.age,
            gender: this.mobileForm.gender,
            code: this.mobileForm.code,
            stationName: this.mobileForm.stationName,
            unitName: this.mobileForm.unitName,
            characterName: this.mobileForm.characterName,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            // this.$message({
            //   message: "注册成功！",
            //   type: "success",
            // });
            this.$notify({
              title: '注册成功',
              message: data.msg,
              type: 'success',
              duration: 0,
              offset: 500
            });
            // this.$router.replace({ name: "login" });
          } else {
            console.log(data);
            // this.$message({
            //   // message: "注册失败",
            //   message: data.msg,
            //   type: "warning",
            // });
            this.$notify({
              title: '提示',
              message: data.msg,
              type: 'warning',
              duration: 0,
              offset: 500
            });
            this.message = data.msg;
          }
        });
      }
    },
    // 转到登录界面
    gotoLogin() {
      this.$router.replace({ name: "login" });
    },
    // 获取短信验证码
    getCode(phone_number) {
      this.$http({
        url: this.$http.adornUrl("/sms/sendCode"),
        method: "get",
        params: this.$http.adornParams({ phone: phone_number }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log("看看数据");
          // console.log(data);
          this.$message({
            message: "验证码已发至您的手机，请注意查收！",
            type: "success",
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    getStationName() {
      this.$http({
        url: this.$http.adornUrl("/enterprise/info/app/getEntInfoById"),
        method: "get",
        params: this.$http.adornParams({ enid: this.enid }),
      }).then(({ data }) => {
        // if (data && data.code === 0) {
        console.log("看看数据");
        console.log(data);
        this.mobileForm.stationName = data.eninfo.uname

        const unitname =  data.eninfo.unitname.split(';');
        // 转换成字典列表
        const checkPhaseOptions = unitname.map((item, index) => {
          return {
            value: (index + 1).toString(),
            label: item
          };
        });
        this.unitname_option = checkPhaseOptions
        // } else {
        //   // this.getCaptcha()
        //   this.$message.error(data.msg);
        // }
      });

    },
    validateAge() {
      if (this.mobileForm.age < 0 || this.mobileForm.age > 100) {
        this.$message({
          message: "年龄应在0到100之间",
          type: "warning",
        });
        this.mobileForm.age = "";  // 清空不合法的年龄输入
      }
    },

  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.index) {
      console.log("看看是否传参：");
      console.log(this.$route.query.index);
      this.enid = this.$route.query.index
    }
    this.getStationName()
  },
};
</script>

<style lang="scss">
// .brand-info__text {
//   text-align: center;
//   // margin: 0 0 22px 0;
//   font-size: 34px;
//   font-weight: 500px;
//   text-transform: uppercase;
// }
.text-home {
  text-align: center;
  font-size: 30px;
  color: firebrick;
  margin-bottom: 10px;
}

.regist-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 550px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
}

.regist-title {
  font-size: 24px;
}

.regist-captcha {
  overflow: hidden;

  >img {
    width: 100%;
    cursor: pointer;
  }
}

.login-btn-submit {
  width: 100%;
  font-size: 18px;
  background-color: #428bca;
  border-color: #428bca;
}

.regist-btn-submit {
  width: 100%;
  font-size: 18px;
  // margin-top: 0px;
  // margin-top: 8px;
  background-color: #428bca;
  border-color: #428bca;
}

.el-input .el-input__inner {
  font-size: 18px
}
</style>
<style lang="scss" scoped>
::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}

/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #428bca !important;
}

/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 125px !important;
  border-radius: 17px;
  top: 0px !important;
  left: -18px !important;
  position: absolute !important;
  z-index: 1;
}

/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}

/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 20px !important;
  width: 104px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  font-size: 18px;
  z-index: 2;
}
</style>
